import morning from '../stories/assets/images/icons/morning.svg';
import afternoon from '../stories/assets/images/icons/afternoon.svg';
import evening from '../stories/assets/images/icons/evening.svg';

import {
	MILLISECONDS_IN_A_DAY,
	MONTHS_IN_A_YEAR,
	dateSeparatorConfig,
	vitalKeys,
	BP,
	NIL,
} from './Constants';

export function roundOf(number = 0, roundTo = 1) {
	if (number === '' || !Number(number)) {
		return '';
	}
	return Number.parseFloat(number).toFixed(roundTo);
}

export function isToday(activityDate, updateOn = new Date()) {
	const currentDate = new Date(activityDate);
	const today = new Date(updateOn);
	return (
		currentDate.getDate() === today.getDate() &&
		currentDate.getMonth() === today.getMonth() &&
		currentDate.getFullYear() === today.getFullYear()
	);
}

export function isSameDay(activityDate, updateOn) {
	const currentDate = new Date(activityDate);
	const today = new Date(updateOn);
	return (
		currentDate.getDate() === today.getDate() &&
		currentDate.getMonth() === today.getMonth() &&
		currentDate.getFullYear() === today.getFullYear()
	);
}

function isYesterday(date) {
	const currentDate = new Date(date);
	const today = new Date();
	const yesterday = new Date(today.getTime() - MILLISECONDS_IN_A_DAY);
	return (
		currentDate.getDate() === yesterday.getDate() &&
		currentDate.getMonth() === yesterday.getMonth() &&
		currentDate.getFullYear() === yesterday.getFullYear()
	);
}

function checkRecentDate(date) {
	if (!date) return '';
	if (isToday(date)) return 'Today';
	if (isYesterday(date)) return 'Yesterday';
	return false;
}

export function dateFormatter(timestamp, dateSeparator, isSeparationAMust) {
	if (!timestamp) return '';

	const TODAY = 'Today';
	const YESTERDAY = 'Yesterday';
	const checkForSeparator = typeof isSeparationAMust === 'undefined' ? true : isSeparationAMust;

	const currentDate = new Date(timestamp);
	let hours = currentDate.getHours();
	let minutes = currentDate.getMinutes();
	const hourFormat = hours >= 12 ? 'PM' : 'AM';
	const date = `0${currentDate.getDate()}`.slice(-2);
	const month = `0${currentDate.getMonth() + 1}`.slice(-2);
	const fullYear = currentDate.getFullYear();

	// 12 Hours format
	hours %= 12;
	hours = `0${hours || 12}`.slice(-2);

	// added 0 if minutes less than 10
	minutes = `0${minutes}`.slice(-2);

	// Standared Date
	const standaredDate = `${date} ${`${MONTHS_IN_A_YEAR[currentDate.getMonth()]}`} ${fullYear}`;

	// Standared Time
	const standaredTime = `${hours}:${minutes} ${hourFormat}`;

	// Standared Date and Time
	const standaredDateTime = `${standaredDate}, ${standaredTime}`;

	// Standared Recent Date [Today or Yesterday]
	const standaredRecentDate = checkRecentDate(timestamp);

	if (dateSeparatorConfig.default === dateSeparator) return standaredDateTime;

	if (dateSeparatorConfig.defaultDate === dateSeparator) return standaredDate;

	if (dateSeparatorConfig.defaultTime === dateSeparator) return standaredTime;

	if (dateSeparatorConfig.defaultRecentDate === dateSeparator)
		return standaredRecentDate || standaredDate;

	if (dateSeparatorConfig.defaultRecentDatetime === dateSeparator)
		return standaredRecentDate ? `${standaredRecentDate}, ${standaredTime}` : standaredDateTime;

	if (dateSeparatorConfig.chat === dateSeparator) {
		if (isToday(timestamp)) return `${hours}:${minutes} ${hourFormat}`;

		if (isYesterday(timestamp)) return YESTERDAY;

		return `${date} ${`${MONTHS_IN_A_YEAR[currentDate.getMonth()]}`} ${fullYear}`;
	}

	if (dateSeparatorConfig.hh_mm_a === dateSeparator) {
		if (isYesterday(timestamp) && !checkForSeparator) return YESTERDAY;

		if (!isToday(timestamp) && !checkForSeparator) {
			return `${date}/${month}/${fullYear}`;
		}

		return `${hours}:${minutes} ${hourFormat}`;
	}

	if (dateSeparatorConfig.L_D_M_Y === dateSeparator) {
		if (isToday(timestamp) && !checkForSeparator) return TODAY;

		if (isYesterday(timestamp) && !checkForSeparator) return YESTERDAY;

		return `${date} ${`${MONTHS_IN_A_YEAR[currentDate.getMonth()]}`} ${fullYear}`;
	}

	if (dateSeparatorConfig.l_d_m_y === dateSeparator) {
		if (isToday(timestamp) && !checkForSeparator) return TODAY;

		if (isYesterday(timestamp) && !checkForSeparator) return YESTERDAY;

		const monthAbbreviation = MONTHS_IN_A_YEAR[currentDate.getMonth()];

		return `${date} ${`${monthAbbreviation}`} ${fullYear}`;
	}

	if (dateSeparatorConfig.hh_mm === dateSeparator) {
		return `${hours}:${minutes}`;
	}

	if (dateSeparatorConfig.yyyy_mm_dd === dateSeparator) {
		return `${fullYear}-${month}-${date}`;
	}

	if (dateSeparatorConfig.d_m === dateSeparator) {
		if (isToday(timestamp) && !checkForSeparator) return TODAY;

		if (isYesterday(timestamp) && !checkForSeparator) return YESTERDAY;
		const monthAbbreviation = MONTHS_IN_A_YEAR[currentDate.getMonth()];
		return `${date} ${`${monthAbbreviation}`}`;
	}

	if (isToday(timestamp)) return TODAY;

	if (isYesterday(timestamp)) return YESTERDAY;

	return `${date}/${month}/${fullYear}`;
}

export function milliSecondsToHHMMSS(milliseconds, format) {
	if (milliseconds === null) return '';
	let hhMm = milliseconds / 1000;
	let hh = parseInt(hhMm / 3600);
	hhMm %= 3600;

	if (format === '12') {
		const amPm = hh >= 12 ? 'pm' : 'am';
		if (amPm === 'am') {
			hh = hh === 0 ? 12 : hh;
		} else {
			hh = hh !== 12 ? hh - 12 : hh;
		}
		return `${String(hh).padStart(2, '0')}:${String(parseInt(hhMm / 60)).padStart(2, '0')} ${amPm}`;
	}
	return `${String(hh).padStart(2, '0')}:${String(parseInt(hhMm / 60)).padStart(2, '0')}`;
}

export function msToTime(duration) {
	let minutes = Math.floor((duration / (1000 * 60)) % 60);
	let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	return { hours, minutes };
}

export function validateEmail(email) {
	const emailRegex =
		/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(String(email).toLowerCase());
}

export function validatePassword(password) {
	// const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
	const pattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).*$/;
	return pattern.test(password);
}

export function alphaNumericWithSpace(value) {
	const pattern = /^[a-z\d\-_\s]+$/i;
	return pattern.test(value);
}

export function isUrl(url) {
	const regexp =
		/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/;
	return regexp.test(url);
}

export function validKeyStrokes(keyCode) {
	const valid =
		(keyCode > 47 && keyCode < 58) ||
		keyCode === 32 ||
		keyCode === 13 ||
		(keyCode > 64 && keyCode < 91) ||
		(keyCode > 95 && keyCode < 112) ||
		(keyCode > 185 && keyCode < 193) ||
		(keyCode > 218 && keyCode < 223);

	return valid;
}

export function byteConverter(byte, byteUnit = false, increment = 0) {
	const unit = byteUnit === true ? 1024 : 1000;
	const unitType = ['Byte', 'KB', 'MB', 'GB', 'TB'];
	let i = increment;
	i += 1;
	const value = byte / unit;
	if (value >= unit) return byteConverter(value, byteUnit, i);
	return `${value.toFixed(1)} ${unitType[i]}`;
}

export function GreetingMessage() {
	const currentMoment = new Date();
	const currentHour = currentMoment.getHours();

	if (currentHour >= 0 && currentHour < 12)
		return {
			message: 'Good morning',
			image: morning,
		};
	if (currentHour >= 12 && currentHour < 16)
		return {
			message: 'Good afternoon',
			image: afternoon,
		};
	if (currentHour >= 16 && currentHour < 24)
		return {
			message: 'Good evening',
			image: evening,
		};
}

export function currentAge(date = new Date(), label = '') {
	const currentDate = new Date();
	const dob = new Date(date);
	const diff = currentDate.getTime() - dob.getTime();
	if (Number.isNaN(diff)) return '---';
	let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	age = age <= 0 ? '< 1' : age;
	return `${age} ${label}`;
}

export function calculateAge(dob = Date.now()) {
	const diffInMs = Date.now() - dob.getTime();
	const age = new Date(diffInMs);

	return Math.abs(age.getUTCFullYear() - 1970);
}

export function getUtcFormat(local = false) {
	const currentDate = new Date();
	const date = local
		? new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000)
		: currentDate;
	return new Date(date).toISOString();
}

export function changeCase(strings = '', type = '') {
	let string = strings;
	if (string === '' || typeof string !== 'string') return false;
	switch (type) {
		case 'lower':
			return string.toLowerCase();
		case 'upper':
			return string.toUpperCase();
		case 'capitalize':
			string = string.toLowerCase();
			return string.charAt(0).toUpperCase() + string.slice(1);
		case 'capitalizeAll':
			string = string.toLowerCase();
			return string
				.split(' ')
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		default:
			return string;
	}
}

export function allowNumberOnly(event) {
	const charCode = event.which ? event.which : event.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		event.preventDefault();
		return false;
	}
	return true;
}

export function allowNumberAndDecimalOnly(txt, evt) {
	const charCode = evt.which ? evt.which : evt.keyCode;

	if (charCode === 46 && txt.value.indexOf('.') === -1) return true;
	return allowNumberOnly(evt);
}

export function titleFormatter(title) {
	if (typeof title !== 'string') throw new Error('Title formatter must be string');
	return title
		.split(' ')
		.map((word, index) => {
			if (index === 0) return word.charAt(0).toUpperCase() + word.slice(1);
			return word.charAt(0).toLowerCase() + word.slice(1);
		})
		.join(' ');
}

export function checkEmpty(data = '', type = '') {
	return data === '' || data === null || data === false ? type : data;
}

export function bpFormatter(data = []) {
	if (data && !Array.isArray(data)) {
		// console.error(`bpFormatter argument must be array type`);
		return {
			lookupKey: BP,
		};
	}

	const systolicData = data.find((item) => item.lookupKey === vitalKeys.systolic);
	const diastolicData = data.find((item) => item.lookupKey === vitalKeys.diastolic);

	if (systolicData && diastolicData) {
		return {
			lookupKey: BP,
			value: `${systolicData.value}/${diastolicData.value}`,
			unitType: `${systolicData.unitType}`,
			reportDate: `${systolicData.reportDate}`,
			normalcy: `${systolicData.normalcy}`,
		};
	}
	return {
		lookupKey: BP,
	};
}

export function ageCalculator(dob) {
	return dob && calculateAge(new Date(dob));
}

export function capitalizeFirstChar(word = '') {
	return word && word.charAt(0).toUpperCase() + word.slice(1);
}

export function ageFinder(dob) {
	const age = ageCalculator(dob);

	if (age <= 0) return '< 1';
	return age;
}

function fourTwoTwoError(error) {
	const response = error || {};
	const { data = {} } = response;
	const { error: err = [] } = data;
	// Currently, only the first error message will be displayed
	return err.length && err[0].message;
}

export function extractMessageFrom(error) {
	const response = error.response || null;

	const msg = fourTwoTwoError(response);

	if (msg) {
		return msg;
	}

	if (response && response.data) {
		return response.data.message;
	}

	return null;
}

export function ageValue(patientAge) {
	if (typeof patientAge !== 'undefined' && patientAge !== null)
		return patientAge === 0 ? '< 1 years' : `${patientAge} years`;

	return NIL;
}

export function convertToAPIDateFormat() {
	const currentMoment = new Date();
	const currentHour = currentMoment.getHours();

	if (currentHour >= 0 && currentHour < 12)
		return {
			message: 'Good morning',
			image: morning,
		};
	if (currentHour >= 12 && currentHour < 16)
		return {
			message: 'Good afternoon',
			image: afternoon,
		};
	if (currentHour >= 16 && currentHour < 24)
		return {
			message: 'Good evening',
			image: evening,
		};
}

export function convertFromAPIDateFormat(date, isIncludedTime) {
	if (isIncludedTime) return date;
	return date.replaceAll('-', '/');
}

export function getFirstOrLastMonthDay(date, isFirst) {
	const year = new Date(date).getFullYear();
	const month = new Date(date).getMonth();
	const day = new Date(date).getDate();
	if (isFirst) {
		const firstDay = new Date(year, month, 1);
		return `${new Date().getFullYear()}-${
			new Date(firstDay).getMonth() + 1 >= 10
				? new Date(firstDay).getMonth() + 1
				: `0${new Date(firstDay).getMonth() + 1}`
		}-${
			new Date(firstDay).getDate() >= 10
				? new Date(firstDay).getDate()
				: `0${new Date(firstDay).getDate()}`
		}`;
	}
	const lastDay = new Date(year, month, day);
	return `${new Date().getFullYear()}-${
		new Date(lastDay).getMonth() + 1 >= 10
			? new Date(lastDay).getMonth() + 1
			: `0${new Date(lastDay).getMonth() + 1}`
	}-${
		new Date(lastDay).getDate() >= 10
			? new Date(lastDay).getDate()
			: `0${new Date(lastDay).getDate()}`
	}`;
}
