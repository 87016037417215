import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import App from './App';
import * as serviceWorker from './serviceWorker';
import envConfig from './config/environment';
import { initializeMixPanel } from './service/MixpanelService';
import { invalidException } from './helpers/Constants';

if (envConfig.currentInstance !== 'dev') {
	Sentry.init({
		dsn: envConfig.sentryDSN,
		autoSessionTracking: true,
		environment: envConfig.currentInstance,
		integrations: [new Integrations.BrowserTracing()],
		release: `wecare-web@${envConfig.latestAppVersion}`,
		beforeSend: (event, hint) => {
			// Check if the error message includes the specific string
			if (
				hint.originalException &&
				hint.originalException.message &&
				invalidException.includes(hint.originalException.message)
			) {
				// Return null to ignore this error in Sentry
				return null;
			}

			// Return the event if it's not the specific error you want to ignore
			return event;
		},
	});
}

initializeMixPanel();
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();
