import TwilioService from '../../service/TwilioService';

const ChatService = {
	initializeChat(token) {
		return TwilioService.initiate(token);
	},

	getSubscribedChannels(channelConfig) {
		return TwilioService.getSubscribedChannels(channelConfig);
	},

	async getMembers(channel) {
		const members = await TwilioService.getMembers(channel);
		return members;
	},

	async getFilteredMembers(channel, currentUser = {}) {
		const members = await this.getMembers(channel);

		if (members && Array.isArray(members)) {
			return members.filter(({ state = {} }) => state.identity !== currentUser.identity);
		}
		return members;
	},

	getCurrentUser(chatObject) {
		return TwilioService.getCurrentUser(chatObject);
	},

	getClientInfo() {
		return TwilioService.getClientInfo();
	},

	async getUser(member) {
		const user = await TwilioService.getUser(member);
		return user;
	},

	async getMessages(channel, messageLimit = 30) {
		const messages = await TwilioService.getMessages(channel, messageLimit);
		return messages;
	},

	async getUnconsumedMessagesCount(channel) {
		const count = await TwilioService.getUnconsumedMessagesCount(channel);
		return count;
	},

	setTyingIndicator(channel) {
		TwilioService.setTyingIndicator(channel);
	},

	async sendMessage(activeChannel, message, attributes = {}) {
		// console.log('MEDIA FILE --- SEND MESSAGE --- TWILIO');
		const index = await TwilioService.sendMessage(activeChannel, message, attributes);
		return index;
	},

	async advanceLastConsumedMessageIndex(activeChannel, messageIndex) {
		const index = await TwilioService.advanceLastConsumedMessageIndex(activeChannel, messageIndex);
		return index;
	},

	async setAllMessagesConsumed(channel) {
		const unReadCount = await TwilioService.setAllMessagesConsumed(channel);
		return unReadCount;
	},

	async updateLastConsumedMessageIndex(channel, messageIndex) {
		const unReadCount = await TwilioService.updateLastConsumedMessageIndex(channel, messageIndex);
		return unReadCount;
	},

	async getPreviousMessages(messageHeaders) {
		const messagePaginator = await TwilioService.getPreviousMessages(messageHeaders);
		if (messagePaginator) {
			const { ...rest } = messagePaginator;
			return rest;
		}
		return messagePaginator;
	},

	async findMember(channel, memberSid) {
		const member = await TwilioService.getMember(channel, memberSid);
		return member;
	},

	async shutdown() {
		TwilioService.shutdown();
	},

	async setPushRegistrationId(payload) {
		const response = await TwilioService.setPushRegistrationId(payload);
		return response;
	},

	async unsetPushRegistrationId(channelType = 'fcm') {
		const response = await TwilioService.unsetPushRegistrationId(channelType);
		return response;
	},

	handlePushNotification(payload) {
		TwilioService.handlePushNotification(payload);
	},
};
export default ChatService;
