import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import avatar from '../../assets/images/avatar.png';

import { GreetingMessage, dateFormatter } from '../../helpers/Utilities';
import { dateSeparatorConfig, Roles, doctorLabel, RolesName } from '../../helpers/Constants';

import SidebarItem from './SideBarItem';
import AppSpinner from '../Spinner';
import {
	Sidebar,
	Header,
	HeaderLogo,
	HeaderRight,
	HeaderInfo,
	Icon,
	PageWrapper,
	InnerWrapper,
	Heading,
	Paragraph,
	Dropdown,
	DropdownList,
	DropdownListSepertor,
	Avatar,
} from '../../stories';
import BellIcon from '../../pages/DashBoard/components/Notifications/BellIcon';
import { getChannels, getChannelsObject } from '../../pages/Chat/chat.state.selectors';
import {
	// chatProfiles,
	saveChatObject,
	//  saveNewChannels, saveUserChannels
} from '../../pages/Chat/chat.store';
import ChatService from '../../pages/Chat/chat.service';
import { checkAndUpdateAccessToken } from '../../service/manageToken';
import { getAccessToken } from '../../service/HttpService';
// import useUsers from '../../pages/Chat/hooks/useUsers';
// import usePushNotification from '../../pages/Chat/hooks/usePushNotification';

function AppLayout(props) {
	const { children, appLogout = () => {}, appPatientLogout = () => {}, layoutProps = {} } = props;
	const { innerWrapper = {} } = layoutProps;
	const { channels: channelList = [] } = useSelector(({ ChatStore }) => getChannels(ChatStore));
	const { channelsData = {} } = useSelector(({ ChatStore }) => getChannelsObject(ChatStore));
	const dispatch = useDispatch();

	const userDetails = useSelector(({ AuthStore }) => {
		const { userDetails = {} } = AuthStore;
		return userDetails;
	});

	const {
		role = '',
		name,
		username,
		friendlyName,
		profile = avatar,
		showLoader = false,
	} = userDetails;

	const [userProfile, setUserProfile] = useState(profile);
	const viewProfile = [Roles.doctor, Roles.coach, Roles.clinicAdmin, Roles.customerSupport];
	const greetings = GreetingMessage();
	const profileName =
		role === Roles.doctor
			? `${doctorLabel}${name || friendlyName || 'No name'}`
			: `${name || friendlyName || username || 'No name'}`;

	// async function getNextSetOfChats() {
	// 	// dispatch(connectingToAChannel(true));
	// 	if (channelsData.hasNextPage) {
	// 		const [channelsSet, channels] = await Promise.all([chatProfiles(), channelsData.nextPage()]);
	// 		const { data = {} } = channelsSet;
	// 		const { ChatChannel = [] } = data;
	// 		await dispatch(saveUserChannels(ChatChannel));
	// 		await dispatch(saveNewChannels(channels));
	// 	}
	// }

	// useUsers(true);
	// usePushNotification(true);

	async function processChannels(channels, chat, items) {
		// Now, if hasNextPage is true
		// call nextPage() to get the records instead of getUserChannelDescriptors()
		if (channels.hasNextPage) {
			channels.nextPage().then((nextPaginator) => {
				processChannels(nextPaginator, chat, items.concat(nextPaginator.items));
			});
		} else {
			channels.items = items;
			dispatch(saveChatObject(chat, channels));
		}
	}

	function getChannel(chat) {
		if (chat)
			ChatService.getSubscribedChannels().then((paginator) => {
				if (paginator) {
					processChannels(paginator, chat, paginator.items);
				}
			});

		// Initiate the recursive function
	}

	useEffect(() => {
		if (channelsData.hasNextPage) {
			getChannel(ChatService.getClientInfo());
		}
	}, [channelList]);

	const initiateToken = () => {
		checkAndUpdateAccessToken(getAccessToken());
	};
	useEffect(() => {
		// Call the function initially
		initiateToken();

		// Set up a timer to call the function every 30 minutes
		const intervalId = setInterval(initiateToken, 10 * 60 * 1000); // 10 minutes

		// Clear the interval on component unmount to avoid memory leaks
		return () => clearInterval(intervalId);
	}, []);

	return (
		<PageWrapper>
			{/* Sidebar */}

			{role !== Roles.patient && (
				<Sidebar open={false}>
					<SidebarItem />
				</Sidebar>
			)}
			{showLoader && <AppSpinner />}
			<InnerWrapper {...innerWrapper}>
				{/* Header */}
				{/* {role !== Roles.patient && ( */}
				<Header>
					<section
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyItems: 'center',
						}}
					>
						<Link to="/dashboard">
							<HeaderLogo style={{ margin: '0px' }} />
						</Link>
						{role === Roles.patient && (
							<HeaderInfo>
								<Heading>
									{window.location.pathname.includes('delete-account') && 'Delete account'}
									{window.location.pathname.includes('insulin-prescription') &&
										'Insulin prescription updates'}
								</Heading>
							</HeaderInfo>
						)}
					</section>

					<HeaderRight>
						{![Roles.customerSupport, Roles.patient].includes(role) && <BellIcon />}
						<HeaderInfo>
							<section style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
								<img style={{ margin: '0 5px' }} src={greetings.image} alt={greetings.message} />
								<Heading>
									{greetings.message}, <span>{profileName}</span>
								</Heading>
							</section>
							<Paragraph headerNameColor>
								{dateFormatter(Date.now(), dateSeparatorConfig.L_D_M_Y, true)}, {RolesName[role]}
							</Paragraph>
						</HeaderInfo>
						<Dropdown
							open={false}
							name={
								<Avatar small rounded>
									<img
										src={userProfile || avatar}
										onError={() => setUserProfile(avatar)}
										alt="User Avatar"
									/>
								</Avatar>
							}
						>
							{viewProfile.includes(role) && (
								<DropdownList>
									<Link to="/profile">
										<Icon iconName="icon-Edit" /> Edit profile
									</Link>
								</DropdownList>
							)}
							{/* <DropdownList>
								<Icon iconName="icon-Settings" />
								Settings
							</DropdownList> */}
							{role === Roles.patient && (
								<DropdownList>
									{window.location.pathname.includes('insulin-prescription') && (
										<Link to="/delete-account">
											<Icon iconName="icon-Delete" /> Delete account
										</Link>
									)}
									{window.location.pathname.includes('delete-account') && (
										<Link to="/insulin-prescription">
											<Icon iconName="icon-Insulin_RBS_icon" /> Insulin prescription
										</Link>
									)}
								</DropdownList>
							)}
							<DropdownListSepertor />
							{role === Roles.patient && (
								<DropdownList onClick={() => appPatientLogout()}>
									<Icon iconName="icon-Logout" />
									Logout
								</DropdownList>
							)}
							{role !== Roles.patient && (
								<DropdownList onClick={() => appLogout()}>
									<Icon iconName="icon-Logout" />
									Logout
								</DropdownList>
							)}
						</Dropdown>
					</HeaderRight>
				</Header>
				{/* )} */}
				{children}
				<div id="scrollView" />
			</InnerWrapper>
		</PageWrapper>
	);
}

export default memo(AppLayout);
