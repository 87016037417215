import styled from 'styled-components';
import { fontBold, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	border: 1px solid ${colors.Border2};
	border-radius: 6px;
	${({ DasboardTable }) =>
		DasboardTable &&
		`border:0px;
    th{
		  padding-left: 0px!important;
		}
		tr{
			border:0px;
		}
	`}

	${({ InsulinTable }) =>
		InsulinTable &&
		`border:0px;
   
		tr{
			border:0px;
		}
	`}

	${({ NoRightSpacing }) =>
		NoRightSpacing &&
		`td{
		padding-right: 5px!important;
		}
	`}
	${({ plainBg }) =>
		plainBg &&
		`tbody {
      tr {
          td {
              background: transparent !important;
          }
      }
		}
    `}
	${({ AlignTop }) =>
		AlignTop &&
		`td{
		vertical-align: top;
		}
	`}
	${({ NoWrapTable }) =>
		NoWrapTable &&
		`td{
		white-space: nowrap;
		}
	`}
	${({ PatientDetails }) =>
		PatientDetails &&
		`border:0px;
		td{
			border: 0px;
			padding: 0px 14px 10px 0px;
			color: ${colors.BlueBase};
		}
		tr{
			border:0px;
			&:hover{
				td{
					background: ${colors.White}!important;
				}
			}
		}
	`}
	${({ BorderDefaultTable }) =>
		BorderDefaultTable &&
		`border:0px;
		th{
			padding: 16px!important;
		}
		td{
			border-bottom: 1px solid ${colors.Border2};
			padding: 16px;
			background: ${colors.White}!important;
			color: ${colors.BlueBase};
		}
		tr{
			border:0px;
			&:hover{
				td{
					background: ${colors.White}!important;
				}
			}
			&:last-child{
				td{
					border-bottom: 1px solid ${colors.Border2}!important;
				}
			}
		}
	`}
	${({ ResponsiveBlockTable }) =>
		ResponsiveBlockTable &&
		`
		@media screen and (max-width: 767px) {
			display: block;
			margin-bottom:20px;
			tbody, th, td, tr {
			display: block;
			}
			td{
				position: relative;
				white-space: normal;
				text-align:left;
				padding: 8px 0px 8px 115px;
				&:before{
					position: absolute;
					top: 7px;
					left: 0px;
					white-space: nowrap;
					text-align:left;
					content: attr(data-title);
					font-family: ${fontRegular.familyName};
					font-weight:normal;
				}
			}
		}

	`}
	${({ InvitationTable }) =>
		InvitationTable && `border-bottom: 1px solid ${colors.Border2}; margin-bottom:20px;`}

	thead {
		tr {
			th {
				text-align: left;
				font-family: ${fontBold.familyName};
				font-weight:800;
				background: #eef0f4;
				border-bottom: 2px solid #cdd3e0;
				font-size: 15px;
				line-height: 18px;
				color: ${colors.Black};
				padding: 14px 15px 14px 33px;
				white-space: nowrap;
				position: sticky;
				top: -1px;
				z-index: 10;
				button {
					margin-left: 10px;
					width: 30px;
					height: 30px;
					border-radius: 6px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: ease-in all 0.3s;
					color: ${colors.Grey6};
					font-size: 14px;
					span {
						font-size: 14px;
					}
				}
				:first-child {
					border-radius: 6px 0 0 0;
				}
				:last-child {
					border-radius: 0 6px 0 0;
				}
				${({ filterHeader }) => filterHeader && `padding:8px 15px 8px 33px;`}
			}
		}
	}
	tbody {
		tr {
			td {
				${({ buttonTable }) => buttonTable && `padding:8px 15px 8px 33px;`}
				${({ Nowrap }) => Nowrap && `white-space:nowrap;`}
			 ${({ InsulinTable }) => InsulinTable && `color: ${colors.BlueBase}; background: ${colors.White};`}
			}
	      ${({ DasboardTable }) =>
					DasboardTable &&
					`color: ${colors.BlueBase}; background: ${colors.White};padding-left:5px; `}
				${({ PatientList }) => PatientList && `padding:15px 15px 16px 5px; font-size: 15px;`}
			}
			:nth-of-type(even) {
				td {
					background: #f5f6f9;
					${({ GroupingTable }) => GroupingTable && `background: ${colors.White}`}
					${({ DasboardTable }) => DasboardTable && `background: ${colors.White};`}
					${({ InsulinTable }) => InsulinTable && `background: ${colors.White};`}
				}
			}
			&:last-child {
				td {
					${({ PatientList }) => PatientList && `border-bottom: 1px solid ${colors.Border2};`}
				}
			}
		}
	}
`;

export default Table;
