import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import ChatService from '../chat.service';
import PushService from '../../../service/PushService';
import {
	saveFcmToken,
	registerUserDevice,
	getLocalStorageValue,
	saveToLocalStorage,
	getNotifications,
} from '../../Login/login.store';

import { UID, FCM_TOKEN } from '../../../helpers/Constants';
import { addChannelToBeLoad } from '../chat.store';

function usePushNotification(isLoggedIn) {
	const dispatch = useDispatch();
	const { fcmToken } = useSelector(({ AuthStore }) => {
		const { userDetails = {} } = AuthStore;
		return { ...userDetails };
	});
	const chatObject = useSelector(({ ChatStore }) => {
		const { chatClientInfo = {} } = ChatStore;
		const { chat } = chatClientInfo;
		return chat;
	});

	useEffect(() => {
		const messagingService = PushService.initialize();

		async function setChatPushId(token) {
			await ChatService.setPushRegistrationId({ channelType: 'fcm', token });
		}

		function handleChatPushNotification(payload) {
			ChatService.handlePushNotification(payload);
		}

		async function getToken() {
			try {
				const fcmToken = await messagingService.getToken();
				let deviceId = getLocalStorageValue(UID);
				saveToLocalStorage({ [FCM_TOKEN]: fcmToken });

				if (!deviceId) {
					deviceId = uuidv4();
					saveToLocalStorage({ [UID]: deviceId });
				}

				await registerUserDevice({
					data: { deviceToken: fcmToken, deviceId },
				});

				dispatch(saveFcmToken(fcmToken));

				// messagingService.onTokenRefresh(getToken);
			} catch (error) {
				// console.log('### FIREBASE ERROR WHILE GETTING TOKEN??? = ', error);
			}
		}

		if (isLoggedIn && !fcmToken) getToken();

		if (!isLoggedIn && messagingService.fcmToken) {
			// Delete token upon user logout
			messagingService.deleteToken(messagingService.fcmToken);
		}

		if (isLoggedIn && chatObject && messagingService.fcmToken) {
			setChatPushId(messagingService.fcmToken);
			messagingService.onMessage((payload) => {
				const { data = {} } = payload;
				const isChannelUpdate = data.twi_message_type === 'twilio.channel.consumption_update';
				if (data.channel_title) handleChatPushNotification(payload);
				if (!isChannelUpdate) {
					dispatch(getNotifications({ params: { limit: 1000, offset: 0 } }));
				}
			});

			navigator.serviceWorker.addEventListener('message', (message) => {
				const payload = message.data.data;
				if (payload.twi_message_type === 'twilio.conversations.new_message') {
					dispatch(addChannelToBeLoad(payload.conversation_sid));
				}
			});
		}
	}, [isLoggedIn, fcmToken, dispatch, chatObject]);

	return null;
}

export default usePushNotification;
