import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

import { getTwilioToken } from '../chat.state.selectors';
import {
	fetchChannels,
	saveChatObject,
	memberIsTyping,
	fetchTwilioToken,
	saveChatToken,
	saveLastMessageDetails,
	removeChannel,
	addNewUser,
	reArrangeChannels,
	removeMessageDetails,
	// saveMessageHistory,
} from '../chat.store';

import ChatService from '../chat.service';

function useUsers(isLoggedIn) {
	const dispatch = useDispatch();
	const [clientChat, setClientChat] = useState(null);

	const twilioToken = useSelector(({ ChatStore }) => getTwilioToken(ChatStore));

	function subscribeToListeners(chatObject) {
		const Chat = chatObject || clientChat;
		if (Chat) {
			Chat.on('typingStarted', (member) => {
				dispatch(memberIsTyping(member, true));
			});
			Chat.on('typingEnded', (member) => {
				dispatch(memberIsTyping(member, false));
			});
			Chat.on('connectionError', async (error) => {
				if (error.message.includes('CPS_UPSTREAM_PER_CONNECTION')) {
					try {
						const { data = {} } = await fetchTwilioToken();
						const { twilioToken = '' } = data;
						Chat.updateToken(twilioToken);
					} catch (error) {
						return false;
					}
					// console.log('CATCH---- chat connectionError', error);
				}
			});
			Chat.on('tokenAboutToExpire', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					const { twilioToken = '' } = data;
					Chat.updateToken(twilioToken);
				} catch (error) {
					// console.log('CATCH---- chat tokenAboutToExpire', error);
					return false;
				}
			});
			Chat.on('connectionStateChanged', (state) => {
				if (state === 'disconnected') {
					// Handle disconnection
					// You can add your logic here, such as notifying the user or attempting to reconnect
				}
			});
			Chat.on('tokenExpired', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					dispatch(saveChatToken(data));
					const { twilioToken = '' } = data;
					Chat.updateToken(twilioToken);
				} catch (error) {
					// console.log('CATCH---- chat tokenExpired', error);
					return false;
				}
			});
			Chat.on('messageRead', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageUpdated', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));

				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageRemoved', async (messageInfo) => {
				// await dispatch(saveMessageHistory(messageInfo));
				await dispatch(removeMessageDetails(messageInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('messageAdded', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('channelAdded', async (channel) => {
				await dispatch(addNewUser(channel));
				dispatch(reArrangeChannels());
			});
			// Chat.on('conversationAdded', async (channel) => {
			// 	await dispatch(addNewUser(channel));
			// });
			Chat.on('memberLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('participantLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				dispatch(reArrangeChannels());
			});
		}
	}

	async function initiateChat() {
		try {
			dispatch(fetchChannels(true));
			const chat = await ChatService.initializeChat(twilioToken);
			if (chat) {
				const channels = await ChatService.getSubscribedChannels();
				// await getChannels(chat);
				setClientChat(chat);
				subscribeToListeners(chat);
				dispatch(saveChatObject(chat, channels));
				dispatch(fetchChannels(false));
			}
		} catch (error) {
			// console.log('CATCH---- chat initialise', error);
			setClientChat(null);
			dispatch(fetchChannels(false));
			dispatch(saveChatObject(null));
		}
	}

	const memomizedCall = useCallback(() => {
		initiateChat();
	});

	useEffect(() => {
		if (isLoggedIn && twilioToken) memomizedCall();
		return () => {};
	}, [twilioToken]);

	return clientChat;
}

export default useUsers;
